import { arcgisURL } from "@/config/api-config";

const maizeFarms = {
  title: "Maize Farms",
  url: arcgisURL + "Maize_Farms_GeoJSON/FeatureServer",
  opacity: 0.8,
  renderer: {
    type: "unique-value",
    field: "farm_type",
    outline: {
      color: "white",
      width: 0.5,
    },
    uniqueValueInfos: [
      {
        value: "Both",
        symbol: {
          type: "simple-marker",
          style: "square", 
          color: "#DC143C",
          size: 12,
          opacity: 0.8,
        },
        label: "Both",
      },
      {
        value: "Commercial",
        symbol: {
          type: "simple-marker",
          style: "sqaure", 
          color: "#F7DC6F",
          size: 12,
          opacity: 0.8,
        },
        label: "Commercial",
      },
      {
        value: "Subsistence",
        symbol: {
          type: "simple-marker",
          style: "square", 
          color: "#FFC107",
          size: 12,
          opacity: 0.8,
        },
        label: "Subsistence",
      },
    ],
  },
  popupTemplate: {
    title: "Maize Farms - {wardname}, {lganame}, {statename}",
    content: [
      {
        type: "fields",
        fieldInfos: [
          {
            fieldName: "wardname",
            label: "Ward",
          },
          {
            fieldName: "lganame",
            label: "LGA",
          },
          {
            fieldName: "statename",
            label: "State",
          },
          {
            fieldName: "poi_name",
            label: "Farm Name",
          },
          {
            fieldName: "farm_type",
            label: "Farm Type",
          },
        ],
      },
    ],
  },
  visible: false,
//   legendEnabled: false,
};

export default maizeFarms;
