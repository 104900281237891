import { arcgisURL } from "@/config/api-config";

const processedMaizeLayer = {
  title: "Maize Farm Trials (IITA)",
  url: arcgisURL + "Processed_Yield_Biomass_LGA_State_Filtered/FeatureServer",
  opacity: 0.8,
  geometryType: "point",
  visible: false,
  renderer: {
    type: "simple",
    symbol: {
      type: "simple-marker",
      color: "#4CAF50",
      size: 12,
      outline: {
        color: "white",
        width: 0.5,
      },
    },
  },
  popupTemplate: {
    title: "Maize Farm Trial - {LGA}, {State}",
    content: [
      {
        type: "fields",
        fieldInfos: [
          {
            fieldName: "YIELD",
            label: "Total Yield (T)",
            visible: true,
            format: { digitSeparator: true, places: 2 },
          },
          {
            fieldName: "Biomass",
            label: "Biomass (T)",
            visible: true,
            format: { digitSeparator: true, places: 2 },
          },
          {
            fieldName: "LGA",
            label: "LGA",
            visible: true,
          },
          {
            fieldName: "State",
            label: "State",
            visible: true,
          },
        ],
      },
    ],
  },
};

export default processedMaizeLayer;
