import {
  computeMinigridSummary,
  computeOilPalmPFSummary,
  getMillsSummary,
  computeTotalAcres,
  profitPerWeek,
} from "../../utils/serviceArea";
import { roundToTwoDP } from "../../helpers";
import { miniGridsData } from "../../data/miniGrids";
import { oilPalmPFData } from "../../data/oilPalmPF";
import FeatureSet from "@arcgis/core/rest/support/FeatureSet";
import ServiceAreaParameters from "@arcgis/core/rest/support/ServiceAreaParameters";
import axios from "axios";

const createServiceAreaParams = (params) => {
  const featureSet = new FeatureSet({
    features: [params.locationGraphic],
  });

  const taskParameters = new ServiceAreaParameters({
    facilities: featureSet,
    defaultBreaks: params.driveTimeCutoffs,
    trimOuterPolygon: true,
    outSpatialReference: params.outSpatialReference,
  });

  return taskParameters;
};

const saColors = [
  "rgba(0, 0, 255,.60)",
  "rgba(158, 42, 43,.60)",
  "rgba(0, 204, 0,.60)",
  "rgba(109, 89, 122,.60)",
  "rgba(230, 230, 0,.60)",
];

const state = () => ({
  serviceAreas: [],
  serviceAreaCount: 0,
  serviceAreaLGA: "",
  serviceAreaXY: {},
  futurePrice: {},
  currentPrice: {},
  loading: false,
});

const getters = {
  getServiceAreaAttributes: (state) => state.serviceAreas,
  getServiceAreaXY: (state) => state.serviceAreaXY,
  getFuturePrice: (state) => state.futurePrice,
  getCurrentPrice: (state) => state.currentPrice,
  getMapLayers: (state) => state.mapLayers,
  getMapView: (state) => state.mapView,
  getServiceAreaCount: (state) => state.serviceAreaCount,
  getLoadingState: (state) => state.loading,
};

const mutations = {
  setServiceAreaAttributes(state, attribute) {
    state.serviceAreas.push(attribute);
  },
  incrementServiceAreaCount(state) {
    state.serviceAreaCount += 1;
  },
  setServiceAreaLGA(state, StartingArea) {
    state.serviceAreaLGA = StartingArea;
  },
  setServiceAreaXY(state, StartingArea) {
    state.serviceAreaXY = StartingArea;
  },
  saveFuturePrice(state, payload) {
    state.futurePrice = payload;
  },
  saveCurrentPrice(state, payload) {
    state.currentPrice = payload;
  },
  setLoading(state, isLoading) {
    state.loading = isLoading;
  },
};

const actions = {
  setServiceAreaLGA({ commit }, serviceAreaLGA) {
    commit("setServiceAreaLGA", serviceAreaLGA);
  },

  setServiceAreaXY({ commit }, serviceAreaXY) {
    commit("setServiceAreaXY", serviceAreaXY);
  },

  async setFuturePrice({ commit }, date) {
    try {
      const response = await axios.get(
        `https://api.fps.releaf.ng/api/future-price/date?date=${date}`
      );
      const futurePriceJson = JSON.stringify(response.data);
      await commit("saveFuturePrice", futurePriceJson);
      return futurePriceJson;
    } catch (error) {
      console.log(error);
    }
  },

  async setCurrentPrice({ commit }) {
    try {
      const response = await axios.get(
        "https://api.fps.releaf.ng/api/current-price"
      );
      const currentPriceJson = JSON.stringify(response.data);
      await commit("saveCurrentPrice", currentPriceJson);
    } catch (error) {
      console.log(error);
    }
  },

  async drawServiceArea({ rootGetters, commit, state }, params) {
    // // Validate locationGraphic
    // if (!params.locationGraphic || !params.locationGraphic.geometry) {
    //   console.error("Invalid locationGraphic:", params.locationGraphic);
    //   alert("Please select a valid starting location for the service area.");
    //   commit("setLoading", false);
    //   params.closeModal();
    //   return;
    // }
    commit("setLoading", true);
    const logisticsCostTime = roundToTwoDP(params.divideServiceAreaValue * 83);
    const mills = rootGetters["mills/getAllMillsData"];
    const oilpalmClusters = rootGetters["oilpalmClusters/getOilPalmClusters"];
    const MAIZE_SEASONS_PER_YEAR = 2; // Assumptions based on 2 seasons per year (average)

    const driveTimeCutoffs = [0, 0, 0]; // Minutes
    driveTimeCutoffs[0] = Math.round(params.divideServiceAreaValue / 3);
    driveTimeCutoffs[1] = Math.round(driveTimeCutoffs[0] * 2);
    driveTimeCutoffs[2] = Math.round(driveTimeCutoffs[0] + driveTimeCutoffs[1]);

    const paramsObj = {
      driveTimeCutoffs: driveTimeCutoffs,
      outSpatialReference: params.outSpatialReference,
      locationGraphic: params.locationGraphic,
    };

    createServiceAreaParams(paramsObj);

    const serviceAreaSummary = {
      StartingArea: "",
      noOfMills: 0,
      totalTonsBought: 0,
      totalNoOfTransactions: 0,
      logisticsCost: logisticsCostTime,
      avgPricePerTonne: 0,
      totalAcres: 0,
      weeklyP1Yield: 0,
      weeklyFFBYield: 0,
      minigridsTotal: 0,
      oilPalmPFTotal: 0,
      color: "",
      profit_week: 0,
      maizeProduction2022: 0,
      maizeProduction2023: 0,
      pksBiomass: 0,
      biocharOutput: 0,
      landForBiochar: 0,
      maizeLandSize2022: 0,
      maizeLandSize2023: 0,
      maizeLandAvailableForBiochar: 0,
    };

    const serviceAreaUrl =
      "https://route-api.arcgis.com/arcgis/rest/services/World/ServiceAreas/NAServer/ServiceArea_World/solveServiceArea";

    try {
      const { serviceAreaPolygons } = await params.serviceArea.solve(
        serviceAreaUrl,
        createServiceAreaParams(paramsObj)
      );

      const graphic = serviceAreaPolygons[0];
      const width = graphic.geometry.extent.width;

      // Setup the graphic first without waiting for computations
      graphic.symbol = {
        type: "simple-fill",
        color: saColors[state.serviceAreaCount],
      };

      params.view.graphics.add(graphic, 0);
      params.closeModal();

      // Query maize production layers using the service area polygon
      const maize2022Layer = params.view.map.layers.find(
        (layer) => layer.title === "Maize Production 2022"
      );
      const maize2023Layer = params.view.map.layers.find(
        (layer) => layer.title === "Maize Production 2023"
      );

      const maizeYieldLayer = params.view.map.layers.find(
        (layer) => layer.title === "Maize Yield (Projection)"
      );

      const [maizeProduction2022, maizeProduction2023, averageMaizeYield] =
        await Promise.all([
          queryMaizeProduction(maize2022Layer, graphic.geometry),
          queryMaizeProduction(maize2023Layer, graphic.geometry),
          queryMaizeYield(maizeYieldLayer, graphic.geometry),
        ]);

      // Run promises in parallel for other summaries
      const [millsSummary, totalAcres, minigridSummary, oilPalmPFSummary] =
        await Promise.all([
          getMillsSummary(mills, params.serviceAreaStartingPoint, width),
          computeTotalAcres(
            oilpalmClusters,
            params.serviceAreaStartingPoint,
            width
          ),
          computeMinigridSummary(
            miniGridsData,
            params.serviceAreaStartingPoint,
            width
          ),
          computeOilPalmPFSummary(
            oilPalmPFData,
            params.serviceAreaStartingPoint,
            width
          ),
        ]);

      // Populate serviceAreaSummary
      serviceAreaSummary.noOfMills = millsSummary.count;
      serviceAreaSummary.totalTonsBought = Math.round(
        millsSummary.totalTonsBought
      ).toLocaleString("en-US");
      serviceAreaSummary.totalNoOfTransactions =
        millsSummary.totalNoOfTransactions;
      serviceAreaSummary.avgPricePerTonne = isNaN(
        millsSummary.totalPrice / millsSummary.totalTonsBought
      )
        ? 0
        : Math.round(millsSummary.totalPrice / millsSummary.totalTonsBought);
      serviceAreaSummary.currentP1Price = Math.round(
        millsSummary.currentP1Price
      ).toLocaleString("en-US");
      serviceAreaSummary.totalAcres =
        Math.round(totalAcres).toLocaleString("en-US");
      serviceAreaSummary.logisticsCost =
        logisticsCostTime.toLocaleString("en-US");
      serviceAreaSummary.weeklyP1Yield = Math.round(
        totalAcres * 0.024
      ).toLocaleString("en-US");
      serviceAreaSummary.weeklyFFBYield = Math.round(
        totalAcres * 0.08
      ).toLocaleString("en-US");
      serviceAreaSummary.minigridsTotal = minigridSummary.count;
      serviceAreaSummary.oilPalmPFTotal = oilPalmPFSummary.count;
      serviceAreaSummary.profit_week = profitPerWeek(
        totalAcres,
        millsSummary.currentP1Price,
        logisticsCostTime
      );

      // Add maize production and related calculations
      serviceAreaSummary.maizeProduction2022 =
        Math.round(maizeProduction2022).toLocaleString("en-US");
      serviceAreaSummary.maizeProduction2023 =
        Math.round(maizeProduction2023).toLocaleString("en-US");

      // Calculate PKS Biomass, Biochar Output, Land for Biochar, and Maize Land Size
      const weeklyP1YieldNum =
        parseFloat(serviceAreaSummary.weeklyP1Yield.replace(/,/g, "")) || 0;
      const pksBiomass = weeklyP1YieldNum * 0.5; // 50% of weeklyP1Yield
      const biocharOutput = (pksBiomass * 250) / 1000; // Convert to tonnes (250 kg/ton PKS, 1000 kg/tonne)
      const landForBiochar = biocharOutput / 2; // Updated to 2 T/HA
      const maizeProduction2022Num = parseFloat(
        serviceAreaSummary.maizeProduction2022.replace(/,/g, "")
      );
      const maizeProduction2023Num = parseFloat(
        serviceAreaSummary.maizeProduction2023.replace(/,/g, "")
      );

      const maizeYieldPerSeason = averageMaizeYield || 2.23; // Fallback to 2.23 (average maize yield) if query fails
      const maizeLandSize2022 =
        maizeProduction2022Num / MAIZE_SEASONS_PER_YEAR / maizeYieldPerSeason;
      const maizeLandSize2023 =
        maizeProduction2023Num / MAIZE_SEASONS_PER_YEAR / maizeYieldPerSeason;

      serviceAreaSummary.pksBiomass =
        Math.round(pksBiomass).toLocaleString("en-US");
      serviceAreaSummary.biocharOutput =
        Math.round(biocharOutput).toLocaleString("en-US");
      serviceAreaSummary.landForBiochar =
        Math.round(landForBiochar).toLocaleString("en-US");
      serviceAreaSummary.maizeLandSize2022 =
        Math.round(maizeLandSize2022).toLocaleString("en-US");
      serviceAreaSummary.maizeLandSize2023 =
        Math.round(maizeLandSize2023).toLocaleString("en-US");

      // Calculate Maize Land Available for Biochar Application (%) using maizeLandSize2023
      const maizeLandAvailableForBiochar =
        landForBiochar > 0
          ? Math.round((landForBiochar / maizeLandSize2023) * 100)
          : 0;
      serviceAreaSummary.maizeLandAvailableForBiochar =
        maizeLandAvailableForBiochar.toLocaleString("en-US") + "%";

      graphic.attributes = {
        totalMills: serviceAreaSummary.noOfMills,
        totalTonsBought: serviceAreaSummary.totalTonsBought,
        totalNoOfTransactions: serviceAreaSummary.totalNoOfTransactions,
        logisticsCost: serviceAreaSummary.logisticsCost,
        avgPricePerTonne:
          serviceAreaSummary.avgPricePerTonne.toLocaleString("en-US"),
        totalAcres: serviceAreaSummary.totalAcres,
        weeklyP1Yield: serviceAreaSummary.weeklyP1Yield,
        weeklyFFBYield: serviceAreaSummary.weeklyFFBYield,
        minigridsTotal: serviceAreaSummary.minigridsTotal,
        oilPalmPFTotal: serviceAreaSummary.oilPalmPFTotal,
        currentP1Price: serviceAreaSummary.currentP1Price,
        profit_week: serviceAreaSummary.profit_week,
        pksBiomass: serviceAreaSummary.pksBiomass,
        biocharOutput: serviceAreaSummary.biocharOutput,
        landForBiochar: serviceAreaSummary.landForBiochar,
        maizeLandSize2022: serviceAreaSummary.maizeLandSize2022,
        maizeLandSize2023: serviceAreaSummary.maizeLandSize2023,
        maizeLandAvailableForBiochar:
          serviceAreaSummary.maizeLandAvailableForBiochar,
      };

      serviceAreaSummary.color = saColors[state.serviceAreaCount];

      const lgaName = state.serviceAreaLGA.split(",")[0];

      graphic.popupTemplate = {
        title: `Service Area Summary for ${lgaName}`,
        content: [
          {
            type: "fields",
            fieldInfos: [
              {
                fieldName: "profit_week",
                label: "Estimated profit/Month (₦)",
                visible: true,
              },
              {
                fieldName: "currentP1Price",
                label: "Current Price Per Tonne (₦)",
                format: { places: 0, digitSeparator: true },
                visible: true,
              },
              {
                fieldName: "avgPricePerTonne",
                label: "Average Historic Price Per Tonne (₦)",
                format: { places: 0, digitSeparator: true },
                visible: true,
              },
              {
                fieldName: "totalNoOfTransactions",
                label: "Total Transactions",
                visible: true,
              },
              {
                fieldName: "totalTonsBought",
                label: "Total Tonnes Bought",
                visible: true,
              },
              {
                fieldName: "totalMills",
                label: "Total Mills",
                visible: true,
              },
              {
                fieldName: "logisticsCost",
                label: "Logistics Cost/T",
                format: { places: 0, digitSeparator: true },
                visible: true,
              },
              {
                fieldName: "totalAcres",
                label: "Total Acres (ha)",
                visible: true,
              },
              {
                fieldName: "minigridsTotal",
                label: "Total Minigrids",
                visible: true,
              },
              {
                fieldName: "oilPalmPFTotal",
                label: "Total Oil Palm Processing Facilities",
                visible: true,
              },
              {
                fieldName: "weeklyP1Yield",
                label: "Weekly P1 Yield (Tonnes)",
                visible: true,
              },
              {
                fieldName: "weeklyFFBYield",
                label: "Weekly FFB Yield (Tonnes)",
                visible: true,
              },
              {
                fieldName: "pksBiomass",
                label: "PKS Biomass (Tonnes)",
                visible: true,
              },
              {
                fieldName: "biocharOutput",
                label: "Biochar Output (Tonnes)",
                visible: true,
              },
              {
                fieldName: "landForBiochar",
                label: "Land Needed for Biochar (HA)",
                visible: true,
              },
              {
                fieldName: "maizeLandSize2022",
                label: "Available Maize Land 2022 (HA)",
                visible: true,
              },
              {
                fieldName: "maizeLandSize2023",
                label: "Available Maize Land 2023 (HA)",
                visible: true,
              },
              {
                fieldName: "maizeLandAvailableForBiochar",
                label: "Maize Land Available for Biochar (%)",
                visible: true,
              },
            ],
          },
        ],
      };

      params.view.graphics.add(graphic, 0);
      params.closeModal();

      commit("setServiceAreaAttributes", serviceAreaSummary);
      commit("incrementServiceAreaCount");
    } catch (error) {
      console.error("Service area error:", error);
      let errorMessage = "Failed to generate service area.";

  if (error?.details?.messages?.length > 0) {
    const detailMessage = error?.details?.messages[0];
    if (detailMessage.includes("unlocated") || detailMessage.includes("Facilities")) {
      errorMessage = "Invalid starting location. Please select a valid point on the map.";
    } else if (detailMessage.includes("maximum break value")) {
      errorMessage = "Drive time exceeds maximum allowed (300 minutes). Please use a smaller value.";
    } else {
      errorMessage += ` Details: ${detailMessage}`;
    }
  } else if (error?.message) {
    errorMessage += ` Details: ${error?.message}`;
  } else {
    errorMessage += " An unexpected error occurred.";
  }

      alert(errorMessage);
      params.closeModal();
    } finally {
      commit("setLoading", false);
    }
  },
};

// Helper function to query maize production
async function queryMaizeProduction(layer, geometry) {
  if (!layer || !geometry) return 0;

  const query = layer.createQuery();
  query.geometry = geometry;
  query.outFields = ["Maize_Prod"];
  query.returnGeometry = false;

  try {
    const response = await layer.queryFeatures(query);
    const totalProduction = response.features.reduce((sum, feature) => {
      return sum + (feature.attributes["Maize_Prod"] || 0);
    }, 0);
    return totalProduction; // In tons
  } catch (error) {
    console.error("Error querying maize production:", error);
    return 0;
  }
}

// Helper function to query maize yield and calculate average
async function queryMaizeYield(layer, geometry) {
  if (!layer || !geometry) return 0;

  const query = layer.createQuery();
  query.geometry = geometry;
  query.outFields = ["Maize_Yield_t_ha"];
  query.returnGeometry = false;

  try {
    const response = await layer.queryFeatures(query);
    const features = response.features;
    if (features.length === 0) return 0;

    const totalYield = features.reduce((sum, feature) => {
      return sum + (feature.attributes["Maize_Yield_t_ha"] || 0);
    }, 0);

    const averageYield = totalYield / features.length; // Average yield in t/ha
    return averageYield;
  } catch (error) {
    console.error("Error querying maize yield:", error);
    return 0;
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
