import { arcgisURL } from "@/config/api-config";

const processedFactories = {
  title: "Processing Factories",
  url: arcgisURL + "Filtered_Factories_GeoJSON/FeatureServer",
  opacity: 0.8,
  renderer: {
    type: "unique-value",
    field: "fctry_st_s",
    outline: {
      color: "white",
      width: 0.5,
    },
    uniqueValueInfos: [
      {
        value: "UnUsed Poor",
        symbol: {
          type: "simple-marker",
          style: "triangle", 
          color: "#DC143C",
          size: 12,
          opacity: 0.8,
        },
        label: "UnUsed Poor",
      },
      {
        value: "Unused Good",
        symbol: {
          type: "simple-marker",
          style: "triangle", 
          color: "#F7DC6F",
          size: 12,
          opacity: 0.8,
        },
        label: "Unused Good",
      },
      {
        value: "In Use",
        symbol: {
          type: "simple-marker",
          style: "triangle", 
          color: "#32CD32",
          size: 12,
          opacity: 0.8,
        },
        label: "In Use",
      },
    ],
  },
  popupTemplate: {
    title: "Processed Factory - {fctry_st_s}",
    content: [
      {
        type: "fields",
        fieldInfos: [
          {
            fieldName: "wardname",
            label: "Ward",
          },
          {
            fieldName: "lganame",
            label: "LGA",
          },
          {
            fieldName: "statename",
            label: "State",
          },
          {
            fieldName: "fctry_st_n",
            label: "Factory Name",
          },
          {
            fieldName: "fctry_st_s",
            label: "Factory Status",
          },
        ],
      },
    ],
  },
  visible: false,
//   legendEnabled: false,
};

export default processedFactories;
