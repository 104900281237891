import { arcgisURL } from "@/config/api-config";


const MaizeAreasRasterLayer = {
  title: "Mazie Growing Area (Main Season)",
  url: `${arcgisURL}maize_cereals/ImageServer`,
  opacity: 0.8,
  visible: false,
//   legendEnabled: false,
  // listMode: "show",
};

export default MaizeAreasRasterLayer;

