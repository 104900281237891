<template>
  <div id="page-top">
    <div id="wrapper">
      <!-- content-wrapper-->
      <div id="content-wrapper" class="d-flex flex-column">
        <div v-if="this.$store.getters['auth/currentUser']">
          <topNav></topNav>
        </div>
        <!-- container-fluid -->
        <div class="seun">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopNav from './TopNav';
import $ from 'jquery';

export default {
  name: 'DefaultLayout',
  components: {
    topNav: TopNav,
  },
  data() {
    return {
      data: [],
    };
  },
  methods: {
    resizeView() {
      if ($(window).width() < 768) {
        $('body').toggleClass('sidebar-toggled');
        $('.sidebar').toggleClass('toggled');
        if ($('.sidebar').hasClass('toggled')) {
          $('.sidebar .collapse').collapse('hide');
        }
      }
    },
  },
  mounted() {
    this.resizeView();
  },
};
</script>

<style scoped>
.seun {
  flex: 1 !important;
}
</style>