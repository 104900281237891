import { arcgisURL } from "@/config/api-config";

// Function to identify pixel value on map click
const identifyPixelValue = async (layer, view, event) => {
  const response = await layer.identify({
    geometry: event.mapPoint,
    returnGeometry: false,
  });

  if (response.value) {
    console.log(`${layer.title} Pixel Value:`, response.value);
    return response.value;
  }

  return "No data";
};


const soilPHLayer = {
  title: "Soil PH",
  url: `${arcgisURL}soil_analysis_PH/ImageServer`,
  opacity: 0.8,
  visible: false,
  popupTemplate: {
    title: "Soil pH Analysis",
    content: [
      {
        type: "fields",
        fieldInfos: [
          {
            fieldName: "Raster.ServicePixelValue",
            label: "Soil pH Value",
            format: { digitSeparator: true },
          },
        ],
      },
    ],
  },
  // legendEnabled: true,
  // listMode: "show",
};

const soilCarbonLayer = {
  title: "Soil Carbon",
  url: `${arcgisURL}soil_carbon/ImageServer`,
  opacity: 0.8,
  visible: false,
  popupTemplate: {
    title: "Soil Carbon Analysis",
    content: [
      {
        type: "fields",
        fieldInfos: [
          {
            fieldName: "Raster.ServicePixelValue",
            label: "Soil Carbon Value",
            format: { digitSeparator: true },
          },
        ],
      },
    ],
  },
  // legendEnabled: true,
  // listMode: "show",
};

const sandTextureLayer = {
  title: "Sand Texture",
  url: `${arcgisURL}sand_texture/ImageServer`,
  opacity: 0.8,
  visible: false,
  popupTemplate: {
    title: "Sand Texture Analysis",
    content: [
      {
        type: "fields",
        fieldInfos: [
          {
            fieldName: "Raster.ServicePixelValue",
            label: "Sand Texture Value",
            format: { digitSeparator: true },
          },
        ],
      },
    ],
  },
  // legendEnabled: true,
  // listMode: "show",
};

export { soilPHLayer, soilCarbonLayer, sandTextureLayer, identifyPixelValue };