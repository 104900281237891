import { showCPOMillClusters } from "@/views/mills/widgets/layer-list/cpo-mills-cluster";
import { showP1InField } from "@/views/mills/widgets/layer-list/P1-in-field";
import { releafTaggedMills } from "@/views/mills/widgets/layer-list/releaf-tagged-mills";

export function layerListActions(
  event,
  weeklyP1YieldSlider,
  Slider,
  oilPalmClusterSlider,
  p1LGASummarySlider,
  buidingFootprintSlider,
  daysSinceLastDiscoverySlider,
  tonnesBoughtTaggedMillsSlider,
  numberOfTxnSlider,
  aveP1PerMillSlider,
  sliderTonnesAtField,
  sliderDaysSincePurchase,
  pickupVehicleCheckboxes,
  agentsDropdownList,
  customerFactoryTypeFilter,
  customerFactoryTypeFilterReset,
  releafFactoryTypeFilter,
  releafFactoryTypeFilterReset,
  p1AtFieldReset,
  cpoTaggedMillsFilterReset,
  gtForDaySinceLastPurchase,
  ltForDaySinceLastPurchase,
  gtForTonnesPurchased,
  ltForTonnesPurchased,
  customerFactoryStatusFilter,
  processedFactoryStatusFilter,
  processedFarmStatusFilter,
  releafFactoryStatusFilter,
  gtDaysSinceLastDiscovery,
  ltDaysSinceLastDiscovery,
  gtTonnesBoughtTaggedMills,
  ltTonnesBoughtTaggedMills,
  gtNumberOfTxn,
  ltNumberOfTxn,
  gtAveP1PerMill,
  ltAveP1PerMill,
  gtBuidingFootprint,
  ltBuidingFootprint,
  gtWeeklyP1Yield,
  ltWeeklyP1Yield,
  gtOilPalmCluster,
  ltOilPalmCluster,
  gtP1LGASummary,
  ltP1LGASummary,
  oilPalmClusterReset,
  handleShowP1Summary,
  handleReleafFactorySummary,
  handleCustomerFactorySummary,
  handleCPOTaggedReleafSummary,
  handleOilpalmClusterSummary,
  handlesNgnLGASummary,
  handlesWardsSummary,
  handleClusteredMillSummary,
  handleDeforestedOilpalmClusterSummary,
  maize2022ProductionLayer,
  maize2023ProductionLayer,
  createCustomLegendPanel,
  RasterStretchRenderer
) {
  const eventItem = event.item;

  if (eventItem.title === "Community transaction summary") {
    eventItem.visible = false;
  }
  if (eventItem.title === "Oil Palm Customers") {
    eventItem.visible = false;
  }
  if (eventItem.title === "Mills Distance") {
    eventItem.visible = false;
  }
  if (eventItem.title === "Logistics Depot") {
    eventItem.visible = false;
  }
  if (eventItem.title === "P1 LMV Pickup") {
    eventItem.visible = false;
  }
  if (eventItem.title === "Akwa Ibom Plantations") {
    eventItem.visible = false;
  }
  if (eventItem.title === "P1 Mill Aggregation") {
    eventItem.visible = false;
  }
  if (eventItem.title === "LGA transaction summary") {
    eventItem.visible = false;
  }
  if (eventItem.title === "Minigrids") {
    eventItem.visible = false;
  }
  if (eventItem.title === "Deforestation Protected Areas") {
    eventItem.visible = false;
  }
  if (eventItem.title === "P1 Aggregation Points") {
    eventItem.visible = false;
  }
  if (eventItem.title === "Community Price Projections") {
    eventItem.visible = false;
  }
  if (eventItem.title === "Active P1 Purchasing Communities") {
    eventItem.visible = false;
  }
  if (eventItem.title === "Current P1 price in communities") {
    eventItem.visible = false;
  }
  if (eventItem.title === "Nigeria Ward Layer") {
    eventItem.visible = false;
  }
  if (eventItem.title === "Nigeria States Boundaries") {
    eventItem.visible = false;
  }
  if (eventItem.title === "Nigeria LGA Layer") {
    eventItem.visible = false;
  }
  if (eventItem.title === "Key Conservation Areas (HCV)") {
    eventItem.visible = false;
  }
  if (eventItem.title === "High Carbon Stock Areas") {
    eventItem.visible = false;
  }
  if (eventItem.title === "Industrial Parks") {
    eventItem.visible = false;
  }
  if (eventItem.title === "Forest Cover Loss") {
    eventItem.visible = false;
  }
  if (eventItem.title === "Forest Cover Gain") {
    eventItem.visible = false;
  }
  if (eventItem.title === "Infrastructure") {
    eventItem.visible = false;
  }
  if (eventItem.title === "Mill Sustainability Scores") {
    eventItem.visible = false;
  }

  //Function for Maize Soil Analysis
  if (eventItem.title === "Maize Soil Analysis") {
    eventItem.visible = false;

    // const divSummaryButton = document.createElement("div");
    // divSummaryButton.className = "btn-summary";
    // divSummaryButton.innerText = "Show Layer Summary";
    // divSummaryButton.addEventListener("click", () => {
    //   handlesNgnLGASummary();
    // });

    if (eventItem.layer.type != "group") {
      eventItem.panel = {
        content: ["legend"],
        className: "esri-icon-sliders-horizontal",
        title: "Layer Properties",
      };
    }
  }

  //Function for Maize Suitability
  if (eventItem.title === "Maize Suitability") {
    eventItem.visible = false;

    // const divSummaryButton = document.createElement("div");
    // divSummaryButton.className = "btn-summary";
    // divSummaryButton.innerText = "Show Layer Summary";
    // divSummaryButton.addEventListener("click", () => {
    //   handlesNgnLGASummary();
    // });

    if (eventItem.layer.type != "group") {
      eventItem.panel = {
        content: ["legend"],
        className: "esri-icon-sliders-horizontal",
        title: "Layer Properties",
      };
    }
  }

  //Function for Processed Maize Yield"
  if (eventItem.title === "Processed Maize Yield") {
    eventItem.visible = false;

    // const divSummaryButton = document.createElement("div");
    // divSummaryButton.className = "btn-summary";
    // divSummaryButton.innerText = "Show Layer Summary";
    // divSummaryButton.addEventListener("click", () => {
    //   handlesNgnLGASummary();
    // });

    if (eventItem.layer.type != "group") {
      eventItem.panel = {
        content: ["legend"],
        className: "esri-icon-sliders-horizontal",
        title: "Layer Properties",
      };
    }
  }

  //Function for Maize Yield Projection
  if (eventItem.title === "Maize Yield Projection") {
    eventItem.visible = false;

    // const divSummaryButton = document.createElement("div");
    // divSummaryButton.className = "btn-summary";
    // divSummaryButton.innerText = "Show Layer Summary";
    // divSummaryButton.addEventListener("click", () => {
    //   handlesNgnLGASummary();
    // });

    if (eventItem.layer.type != "group") {
      eventItem.panel = {
        content: ["legend"],
        className: "esri-icon-sliders-horizontal",
        title: "Layer Properties",
      };
    }
  }

  if (eventItem.layer.type != "group") {
    // don't show legend twice
    eventItem.panel = {
      content: "legend",
      open: false,
      className: "esri-icon-sliders-horizontal",
      title: "Layer Properties",
    };
  }

  //Function for Deforested Layers
  if (eventItem.title === "Potential Deforestation Encroachment ") {
    eventItem.visible = false;
    const divSummaryButton = document.createElement("div");
    divSummaryButton.className = "btn-summary";
    divSummaryButton.innerText = "Show Layer Summary";
    divSummaryButton.addEventListener("click", () => {
      handleDeforestedOilpalmClusterSummary();
    });

    if (eventItem.layer.type != "group") {
      eventItem.panel = {
        content: ["legend", "&nbsp;", divSummaryButton],
        className: "esri-icon-sliders-horizontal",
        title: "Layer Properties",
      };
    }
  }

  //Function for Nigeria LGA Boundaries
  if (eventItem.title === "Nigeria LGA Boundaries") {
    eventItem.visible = false;

    const divSummaryButton = document.createElement("div");
    divSummaryButton.className = "btn-summary";
    divSummaryButton.innerText = "Show Layer Summary";
    divSummaryButton.addEventListener("click", () => {
      handlesNgnLGASummary();
    });

    if (eventItem.layer.type != "group") {
      eventItem.panel = {
        content: ["legend", "&nbsp;", divSummaryButton],
        className: "esri-icon-sliders-horizontal",
        title: "Layer Properties",
      };
    }
  }

  //Function for Nigeria Ward Boundaries
  if (eventItem.title === "Nigeria Ward Boundaries") {
    eventItem.visible = false;

    const divSummaryButton = document.createElement("div");
    divSummaryButton.className = "btn-summary";
    divSummaryButton.innerText = "Show Layer Summary";
    divSummaryButton.addEventListener("click", () => {
      handlesWardsSummary();
    });

    if (eventItem.layer.type != "group") {
      eventItem.panel = {
        content: ["legend", "&nbsp;", divSummaryButton],
        className: "esri-icon-sliders-horizontal",
        title: "Layer Properties",
      };
    }
  }

  //Function for Releaf Factory filter
  if (eventItem.title === "Releaf Assets") {
    eventItem.visible = false;

    const divSummaryButton = document.createElement("div");
    divSummaryButton.className = "btn-summary";
    divSummaryButton.innerText = "Show Layer Summary";
    divSummaryButton.addEventListener("click", () => {
      handleReleafFactorySummary();
    });

    // Create radio button for releaf factory type filter
    let radioGroupFactoryType = document.createElement("div");
    let factoryTypeRadioLabels = [
      "Refinery",
      "Crushing Mill",
      "Deshelling Mill",
      "Kraken II",
      "Carbonizer",
    ];
    factoryTypeRadioLabels.forEach((label, index) => {
      let optionWrapper = document.createElement("div");
      optionWrapper.className = "radio-option";

      let checkbox = document.createElement("input");
      checkbox.className = "radioButton";
      checkbox.type = "checkbox";
      checkbox.name = "checkboxGroupFactoryType";
      checkbox.value = label;
      checkbox.addEventListener("change", () => {
        var selectedOptions = [];
        var checkboxes = document.getElementsByName("checkboxGroupFactoryType");

        for (var i = 0; i < checkboxes.length; i++) {
          if (checkboxes[i].checked) {
            selectedOptions.push(checkboxes[i].value);
          }
        }
        releafFactoryTypeFilter(selectedOptions);
      });

      optionWrapper.appendChild(checkbox);

      let radioLabel = document.createElement("label");
      radioLabel.htmlFor = `radio${index}`;
      radioLabel.textContent = label;
      radioLabel.className = "radio-label";
      optionWrapper.appendChild(radioLabel);

      radioGroupFactoryType.appendChild(optionWrapper);
    });

    // Create radio button for releaf factory status filter
    let radioGroupFactoryStatus = document.createElement("div");
    let factoryStatusRadioLabels = [
      "Active",
      "Inactive",
      "Underconstruction",
      "Planned (2025)",
      "Planned (2026)",
    ];
    factoryStatusRadioLabels.forEach((label, index) => {
      let optionWrapper = document.createElement("div");
      optionWrapper.className = "radio-option";

      let radio = document.createElement("input");
      radio.className = "radioButton";
      radio.type = "radio";
      radio.name = "radioGroupFactoryStatus";
      radio.id = `radio${index}`;
      radio.addEventListener("change", () => {
        releafFactoryStatusFilter(index);
      });
      optionWrapper.appendChild(radio);

      let radioLabel = document.createElement("label");
      radioLabel.htmlFor = `radio${index}`;
      radioLabel.textContent = label;
      radioLabel.className = "radio-label";
      optionWrapper.appendChild(radioLabel);

      radioGroupFactoryStatus.appendChild(optionWrapper);
    });

    //Reset button
    let resetButton = document.createElement("button");
    resetButton.className = "resetButton";
    resetButton.innerText = "Reset";
    resetButton.addEventListener("click", () => {
      const radioButtons = [...document.querySelectorAll(".radioButton")];
      radioButtons.forEach((radioButton) => {
        radioButton.checked = false;
        releafFactoryTypeFilterReset();
      });
    });

    if (eventItem.layer.type != "group") {
      eventItem.panel = {
        content: [
          "legend",
          "Factory Type Filter",
          "&nbsp;",
          radioGroupFactoryType, // Add radio button with 3 labels to the panel
          "&nbsp;",
          "Factory Status Filter",
          "&nbsp;",
          radioGroupFactoryStatus,
          "&nbsp;",
          resetButton,
          "&nbsp;",
          divSummaryButton,
        ],
        className: "esri-icon-sliders-horizontal",
        title: "Layer Properties",
      };
    }
  }

  // Function for Customer Factory type filters
  if (eventItem.title === "Customer Factories") {
    eventItem.visible = false;

    const divSummaryButton = document.createElement("div");
    divSummaryButton.className = "btn-summary";
    divSummaryButton.innerText = "Show Layer Summary";
    divSummaryButton.addEventListener("click", () => {
      handleCustomerFactorySummary();
    });

    // Create checkbox button for customer factory type
    let checkboxGroupFactoryType = document.createElement("div");
    let factoryTypeCheckboxLabels = [
      "Refinery",
      "Crushing Mill",
      "Deshelling Mill",
      "Kraken II",
    ];
    factoryTypeCheckboxLabels.forEach((label, index) => {
      let optionWrapper = document.createElement("div");
      optionWrapper.className = "radio-option";

      let checkbox = document.createElement("input");
      checkbox.className = "radioButton";
      checkbox.type = "checkbox";
      checkbox.name = "checkboxGroupFactoryType";
      checkbox.value = label;
      checkbox.addEventListener("change", () => {
        var selectedOptions = [];
        var checkboxes = document.getElementsByName("checkboxGroupFactoryType");

        for (var i = 0; i < checkboxes.length; i++) {
          if (checkboxes[i].checked) {
            selectedOptions.push(checkboxes[i].value);
          }
        }
        customerFactoryTypeFilter(selectedOptions);
      });

      optionWrapper.appendChild(checkbox);
      let radioLabel = document.createElement("label");
      radioLabel.htmlFor = `radio${index}`;
      radioLabel.textContent = label;
      radioLabel.className = "radio-label";
      optionWrapper.appendChild(radioLabel);

      checkboxGroupFactoryType.appendChild(optionWrapper);
    });

    // Create radio button for customer factory status  filter
    let radioGroupFactoryStatus = document.createElement("div");
    let factoryStatusRadioLabels = ["Active", "Inactive", "Underconstruction"];
    factoryStatusRadioLabels.forEach((label, index) => {
      let optionWrapper = document.createElement("div");
      optionWrapper.className = "radio-option";

      let radio = document.createElement("input");
      radio.className = "radioButton";
      radio.type = "radio";
      radio.name = "radioGroupFactoryStatus";
      radio.id = `radio${index}`;
      radio.addEventListener("change", () => {
        customerFactoryStatusFilter(index);
      });
      optionWrapper.appendChild(radio);

      let radioLabel = document.createElement("label");
      radioLabel.htmlFor = `radio${index}`;
      radioLabel.textContent = label;
      radioLabel.className = "radio-label";
      optionWrapper.appendChild(radioLabel);

      radioGroupFactoryStatus.appendChild(optionWrapper);
    });

    //Reset button
    let resetButton = document.createElement("button");
    resetButton.className = "resetButton";
    resetButton.innerText = "Reset";
    resetButton.addEventListener("click", () => {
      const radioButtons = [...document.querySelectorAll(".radioButton")];
      radioButtons.forEach((radioButton) => {
        radioButton.checked = false;
        customerFactoryTypeFilterReset();
      });
    });

    if (eventItem.layer.type != "group") {
      eventItem.panel = {
        content: [
          "legend",
          "Factory Type Filter",
          "&nbsp;",
          checkboxGroupFactoryType, // Add radio button with 3 labels to the panel
          "&nbsp;",
          "Factory Status Filter",
          "&nbsp;",
          radioGroupFactoryStatus,
          "&nbsp;",
          resetButton,
          "&nbsp;",
          divSummaryButton,
        ],
        className: "esri-icon-sliders-horizontal",
        title: "Layer Properties",
      };
    }
  }

  //Function for P1 at Field with slider
  if (eventItem.title === "P1 Purchases (Field)") {
    const __ret = showP1InField(
      eventItem,
      sliderTonnesAtField,
      Slider,
      sliderDaysSincePurchase,
      gtForDaySinceLastPurchase,
      ltForDaySinceLastPurchase,
      gtForTonnesPurchased,
      ltForTonnesPurchased,
      pickupVehicleCheckboxes,
      agentsDropdownList,
      p1AtFieldReset,
      sliderResetP1AtField,
      handleShowP1Summary
    );
    sliderTonnesAtField = __ret.sliderTonnesAtField;
    sliderDaysSincePurchase = __ret.sliderDaysSincePurchase;
  }
  if (eventItem.title === "P1 Purchases (Field)") {
    eventItem.visible = false;
  }
  function sliderResetP1AtField() {
    sliderDaysSincePurchase.values = [0];
    sliderTonnesAtField.values = [0];
  }

  //layer list for oilpalmcluster with slider
  if (eventItem.title === "Niger Delta Planted Oil Palm") {
    const divSummaryButton = document.createElement("div");
    divSummaryButton.className = "btn-summary";
    divSummaryButton.innerText = "Show Layer Summary";
    divSummaryButton.addEventListener("click", () => {
      handleOilpalmClusterSummary();
    });

    eventItem.visible = true;
    weeklyP1YieldSlider = new Slider({
      min: 0,
      max: 300,
      values: [0],
      precision: 0,
      visibleElements: {
        labels: true,
        rangeLabels: true,
      },
    });

    oilPalmClusterSlider = new Slider({
      min: 0,
      max: 20,
      values: [0],
      precision: 0,
      visibleElements: {
        labels: true,
        rangeLabels: true,
      },
    });

    //sign function - weeklyP1YieldSlider
    let switch1 = document.createElement("label");
    let input1 = document.createElement("input");
    let span1 = document.createElement("span");
    switch1.className = "switch";
    input1.type = "checkbox";
    span1.className = "slider round";
    switch1.appendChild(input1);
    switch1.appendChild(span1);
    input1.checked = true;

    input1.addEventListener("change", (event) => {
      if (event.target.checked) {
        gtWeeklyP1Yield();
      } else {
        ltWeeklyP1Yield();
      }
    });

    //sign function - oilPalmClusterSlider
    let switch2 = document.createElement("label");
    let input2 = document.createElement("input");
    let span2 = document.createElement("span");
    switch2.className = "switch";
    input2.type = "checkbox";
    span2.className = "slider round";
    switch2.appendChild(input2);
    switch2.appendChild(span2);
    input2.checked = true;

    input1.addEventListener("change", (event) => {
      if (event.target.checked) {
        gtOilPalmCluster();
      } else {
        ltOilPalmCluster();
      }
    });

    //Reset button
    let resetButton = document.createElement("button");
    resetButton.className = "resetButton";
    resetButton.innerText = "Reset";
    resetButton.addEventListener("click", () => {
      oilPalmClusterReset();
      slidersResetOilPalmCluster();
    });

    if (eventItem.layer.type != "group") {
      eventItem.panel = {
        content: [
          "legend",
          "Toggle off the switch to reverse the slider function to '< less than function'.",
          "&nbsp;",
          "Weekly P1 Yield (T)",
          "&nbsp;",
          switch1,
          "&nbsp;",
          weeklyP1YieldSlider,
          "&nbsp;",
          "&nbsp;",
          "Palm Cluster by Age",
          "&nbsp;",
          switch2,
          "&nbsp;",
          oilPalmClusterSlider,
          "&nbsp;",
          resetButton,
          "&nbsp;",
          divSummaryButton,
        ],
        className: "esri-icon-sliders-horizontal",
        title: "Layer Properties",
      };
    }
  }

  function slidersResetOilPalmCluster() {
    weeklyP1YieldSlider.values = [0];
    oilPalmClusterSlider.values = [0];
  }

  //LGA Summary Layer with slider
  if (eventItem.title === "P1 LGA Summary") {
    eventItem.visible = false;
    p1LGASummarySlider = new Slider({
      min: 0,
      max: 73000,
      values: [0],
      precision: 0,
      visibleElements: {
        labels: true,
        rangeLabels: true,
      },
    });

    //sign function - ForsliderDaysSincePurchase
    let switch1 = document.createElement("label");
    let input1 = document.createElement("input");
    let span1 = document.createElement("span");
    switch1.className = "switch";
    input1.type = "checkbox";
    span1.className = "slider round";
    switch1.appendChild(input1);
    switch1.appendChild(span1);
    input1.checked = true;

    input1.addEventListener("change", (event) => {
      if (event.target.checked) {
        gtP1LGASummary();
      } else {
        ltP1LGASummary();
      }
    });
    eventItem.panel = {
      content: [
        "legend",
        "Toggle off the switch to reverse the slider function to '< less than function'.",
        "&nbsp;",
        "Weekly P1 Yield (T)",
        "&nbsp;",
        switch1,
        "&nbsp;",
        p1LGASummarySlider,
      ],
      className: "esri-icon-sliders-horizontal",
      title: "Layer Properties",
    };
  }

  // Maize Production Logic
  if (
    eventItem.title === "Maize Production 2022" ||
    eventItem.title === "Maize Production 2023"
  ) {
    eventItem.actionsSections = [
      [
        {
          className: "esri-icon-sliders-horizontal",
          title: "Layer Properties",
          id: "toggle-panel",
        },
      ],
    ];
    const panelContent = createCustomLegendPanel(
      eventItem.title.includes("2022")
        ? maize2022ProductionLayer
        : maize2023ProductionLayer,
      eventItem.title.includes("2022") ? 2022 : 2023
    );
    eventItem.panel = {
      content: panelContent,
      open: false,
      visible: true,
    };
  }

  //Building footprints layer with slider
  if (eventItem.title === "Roof Footprint") {
    eventItem.visible = false;
    buidingFootprintSlider = new Slider({
      min: 0,
      max: 10000,
      values: [0],
      precision: 0,
      visibleElements: {
        labels: true,
        rangeLabels: true,
      },
    });

    //sign function - DaysSinceLastDiscovery
    let switch1 = document.createElement("label");
    let input1 = document.createElement("input");
    let span1 = document.createElement("span");
    switch1.className = "switch";
    input1.type = "checkbox";
    span1.className = "slider round";
    switch1.appendChild(input1);
    switch1.appendChild(span1);
    input1.checked = true;

    input1.addEventListener("change", (event) => {
      if (event.target.checked) {
        gtBuidingFootprint();
      } else {
        ltBuidingFootprint();
      }
    });
    eventItem.panel = {
      content: [
        "legend",
        "Toggle off the switch to reverse the slider function to '< less than function'.",
        "&nbsp;",
        "Building Footprints (m²)",
        "&nbsp;",
        "&nbsp;",
        switch1,
        "&nbsp;",
        buidingFootprintSlider,
        "&nbsp;",
      ],
      className: "esri-icon-sliders-horizontal",
      title: "Layer Properties",
    };
  }

  //cpo mills tagged by releaf with slider
  if (eventItem.title === "Confirmed Mills") {
    const __ret = releafTaggedMills(
      eventItem,
      daysSinceLastDiscoverySlider,
      Slider,
      tonnesBoughtTaggedMillsSlider,
      numberOfTxnSlider,
      gtDaysSinceLastDiscovery,
      ltDaysSinceLastDiscovery,
      gtTonnesBoughtTaggedMills,
      ltTonnesBoughtTaggedMills,
      gtNumberOfTxn,
      ltNumberOfTxn,
      cpoTaggedMillsFilterReset,
      sliderResetCPOtaggedMills,
      handleCPOTaggedReleafSummary
    );
    daysSinceLastDiscoverySlider = __ret.daysSinceLastDiscoverySlider;
    tonnesBoughtTaggedMillsSlider = __ret.tonnesBoughtTaggedMillsSlider;
    numberOfTxnSlider = __ret.numberOfTxnSlider;
  }

  if (eventItem.title === "Processing Factories") {
    eventItem.visible = false;

    // const divSummaryButton = document.createElement("div");
    // divSummaryButton.className = "btn-summary";
    // divSummaryButton.innerText = "Show Layer Summary";
    // divSummaryButton.addEventListener("click", () => {
    //   handleProcessedFactorySummary(); // Define this function if needed
    // });

    // Create checkbox group for factory status
    let checkboxGroupFactoryStatus = document.createElement("div");
    checkboxGroupFactoryStatus.style.marginTop = "10px";
    let factoryStatusCheckboxLabels = ["UnUsed Poor", "Unused Good", "In Use"];
    factoryStatusCheckboxLabels.forEach((label, index) => {
      let optionWrapper = document.createElement("div");
      optionWrapper.className = "radio-option";

      let checkbox = document.createElement("input");
      checkbox.className = "radioButton";
      checkbox.type = "checkbox";
      checkbox.name = "checkboxGroupFactoryStatus";
      checkbox.value = label;
      checkbox.checked = true; // All checked by default (or set only "In Use" to true)
      // If you want only "In Use" checked by default, uncomment the following:
      // checkbox.checked = label === "In Use";
      checkbox.addEventListener("change", () => {
        var selectedOptions = [];
        var checkboxes = document.getElementsByName(
          "checkboxGroupFactoryStatus"
        );
        for (var i = 0; i < checkboxes.length; i++) {
          if (checkboxes[i].checked) {
            selectedOptions.push(checkboxes[i].value);
          }
        }
        processedFactoryStatusFilter(selectedOptions);
      });

      optionWrapper.appendChild(checkbox);
      let checkboxLabel = document.createElement("label");
      checkboxLabel.htmlFor = `checkbox${index}`;
      checkboxLabel.textContent = label;
      checkboxLabel.className = "radio-label";
      optionWrapper.appendChild(checkboxLabel);

      checkboxGroupFactoryStatus.appendChild(optionWrapper);
    });

    // Reset button
    let resetButton = document.createElement("button");
    resetButton.className = "resetButton";
    resetButton.innerText = "Reset";
    resetButton.addEventListener("click", () => {
      const checkboxes = [...document.querySelectorAll(".radioButton")];
      checkboxes.forEach((checkbox) => {
        checkbox.checked = true; // Reset to all checked
        // If you want only "In Use" checked by default, uncomment the following:
        // checkbox.checked = checkbox.value === "In Use";
      });
      processedFactoryStatusFilter(factoryStatusCheckboxLabels); // Reset filter to all
    });

    if (eventItem.layer.type !== "group") {
      eventItem.panel = {
        content: [
          "legend",
          "Factory Status Filter",
          " ",
          checkboxGroupFactoryStatus,
          " ",
          resetButton,
          " ",
          // divSummaryButton,
        ],
        className: "esri-icon-sliders-horizontal",
        title: "Layer Properties",
      };
    }
  }
  if (eventItem.title === "Maize Farms") {
    eventItem.visible = false;

    // const divSummaryButton = document.createElement("div");
    // divSummaryButton.className = "btn-summary";
    // divSummaryButton.innerText = "Show Layer Summary";
    // divSummaryButton.addEventListener("click", () => {
    //   handleProcessedFactorySummary(); // Define this function if needed
    // });

    // Create checkbox group for factory status
    let checkboxGroupFarmStatus = document.createElement("div");
    checkboxGroupFarmStatus.style.marginTop = "10px";
    let farmStatusCheckboxLabels = ["Both", "Commercial", "Subsistence"];
    farmStatusCheckboxLabels.forEach((label, index) => {
      let optionWrapper = document.createElement("div");
      optionWrapper.className = "radio-option";

      let checkbox = document.createElement("input");
      checkbox.className = "radioButton";
      checkbox.type = "checkbox";
      checkbox.name = "checkboxGroupFarmStatus";
      checkbox.value = label;
      checkbox.checked = true; // All checked by default (or set only "Both" to true)
      // If you want only "Both" checked by default, uncomment the following:
      // checkbox.checked = label === "Both";
      checkbox.addEventListener("change", () => {
        var selectedOptions = [];
        var checkboxes = document.getElementsByName(
          "checkboxGroupFarmStatus"
        );
        for (var i = 0; i < checkboxes.length; i++) {
          if (checkboxes[i].checked) {
            selectedOptions.push(checkboxes[i].value);
          }
        }
        processedFarmStatusFilter(selectedOptions);
      });

      optionWrapper.appendChild(checkbox);
      let checkboxLabel = document.createElement("label");
      checkboxLabel.htmlFor = `checkbox${index}`;
      checkboxLabel.textContent = label;
      checkboxLabel.className = "radio-label";
      optionWrapper.appendChild(checkboxLabel);

      checkboxGroupFarmStatus.appendChild(optionWrapper);
    });

    // Reset button
    let resetButton = document.createElement("button");
    resetButton.className = "resetButton";
    resetButton.innerText = "Reset";
    resetButton.addEventListener("click", () => {
      const checkboxes = [...document.querySelectorAll(".radioButton")];
      checkboxes.forEach((checkbox) => {
        checkbox.checked = true; // Reset to all checked
        // If you want only "In Use" checked by default, uncomment the following:
        // checkbox.checked = checkbox.value === "In Use";
      });
      processedFarmStatusFilter(farmStatusCheckboxLabels); // Reset filter to all
    });

    if (eventItem.layer.type !== "group") {
      eventItem.panel = {
        content: [
          "legend",
          "Maize Farms Status Filter",
          " ",
          checkboxGroupFarmStatus,
          " ",
          resetButton,
          " ",
          // divSummaryButton,
        ],
        className: "esri-icon-sliders-horizontal",
        title: "Layer Properties",
      };
    }
  }

  if (eventItem.title === "Soil Analysis") {
    eventItem.visible = false; // Layer not visible by default
  
    const divSummaryButton = document.createElement("div");
    divSummaryButton.className = "btn-summary";
    divSummaryButton.innerText = "Show Layer Summary";
    divSummaryButton.addEventListener("click", () => {
      // handleSoilAnalysisSummary(); // Define this if needed
    });
  
    const toggleContainer = document.createElement("div");
    toggleContainer.className = "toggle-container";
  
    const soilProperties = [
      {
        name: "Soil pH",
        field: "SoilPHDescription",
        ranges: [
          { label: "Acidic (<5.5) - Biochar improves nutrient availability", value: "Acidic (<5.5) - Biochar improves nutrient availability", color: "#FF6347" },
          { label: "Neutral (5.5-7.4) - Optimal for maize", value: "Neutral (5.5-7.4) - Optimal for maize", color: "#FFD700" },
          { label: "Alkaline (>7.4) - Biochar impact varies", value: "Alkaline (>7.4) - Biochar impact varies", color: "#4682B4" },
        ],
      },
      {
        name: "Soil Carbon",
        field: "SoilCarbonDescription",
        ranges: [
          { label: "Low SOC (< 12 g/kg) - Biochar improves sustainability", value: "Low SOC (< 12 g/kg) - Biochar improves sustainability", color: "#D3D3D3" },
          { label: "Moderate SOC (≥ 12 g/kg)) - Biochar still beneficial", value: "Moderate SOC (≥ 12 g/kg)) - Biochar still beneficial", color: "#8A2BE2" },
        ],
      },
      {
        name: "Sand Texture",
        field: "SandDescription",
        ranges: [
          {
            label: "Low sand (<60%) - Biochar impact on moisture retention is lower",
            value: "Low sand (<60%) - Biochar impact on moisture retention is lower",
            color: "#DC143C",
          },
          {
            label: "Moderate sand (60-70%) - Biochar enhances water-holding",
            value: "Moderate sand (60-70%) - Biochar enhances water-holding",
            color: "#F7DC6F",
          },
          {
            label: "High sand (>70%) - Biochar significantly improves moisture",
            value: "High sand (>70%) - Biochar significantly improves moisture",
            color: "#32CD32",
          },
        ],
      },
    ];
  
    let activeProperty = null; // No default active property
    const selectedFilters = {
      "Soil pH": [],
      "Soil Carbon": [],
      "Sand Texture": [], // No default checked state
    };
  
    const updateUI = () => {
      const allToggleWrappers = toggleContainer.querySelectorAll(".toggle-wrapper");
      allToggleWrappers.forEach((wrapper) => {
        const radio = wrapper.querySelector(".toggle-radio");
        const checkboxGroup = wrapper.querySelector(".checkbox-group");
        if (radio.checked) {
          radio.classList.add("active");
          checkboxGroup.style.display = "block";
        } else {
          radio.classList.remove("active");
          checkboxGroup.style.display = "none";
        }
      });
    };
  
    const applySoilFilter = () => {
      const activeProp = soilProperties.find((p) => p.name === activeProperty);
      if (eventItem.layer.visible && activeProp && selectedFilters[activeProperty].length > 0) {
        eventItem.layer.renderer = createRenderer(activeProp);
        const conditions = selectedFilters[activeProperty].map(
          (value) => `${activeProp.field} = '${value}'`
        );
        eventItem.layer.definitionExpression = conditions.join(" OR ");
      } else {
        eventItem.layer.definitionExpression = "";
        eventItem.layer.renderer = soilAnalysisDefaultRenderer();
      }
    };
  
    const soilAnalysisDefaultRenderer = () => {
      return eventItem.layer.renderer; // Use the layer's default renderer
    };
  
    const createRenderer = (property) => {
      return {
        type: "unique-value",
        field: property.field,
        uniqueValueInfos: property.ranges.map((range) => ({
          value: range.value,
          symbol: {
            type: "simple-marker",
            color: range.color,
            size: 12,
            opacity: 0.8,
            outline: { color: "gray", width: 0.5 },
          },
        })),
      };
    };
  
    // Watch for visibility changes and set default state when layer becomes visible
    eventItem.watch("visible", (newValue) => {
      if (newValue && !activeProperty) { // Only set default if no active property
        activeProperty = "Sand Texture";
        selectedFilters["Sand Texture"] = soilProperties.find(p => p.name === "Sand Texture").ranges.map(r => r.value);
        const sandTextureRadio = toggleContainer.querySelector(`#toggle-${soilProperties.findIndex(p => p.name === "Sand Texture")}`);
        sandTextureRadio.checked = true;
        const sandTextureCheckboxes = toggleContainer.querySelectorAll(`input[name="Sand Texture-checkbox"]`);
        sandTextureCheckboxes.forEach(checkbox => (checkbox.checked = true));
        updateUI();
        applySoilFilter();
      } else if (!newValue) {
        // Optional: Reset state when layer is hidden (uncomment if desired)
        // activeProperty = null;
        // soilProperties.forEach((prop) => {
        //   selectedFilters[prop.name] = [];
        //   const checkboxes = document.getElementsByName(`${prop.name}-checkbox`);
        //   checkboxes.forEach((checkbox) => (checkbox.checked = false));
        // });
        // const radios = document.getElementsByName("soilPropertyToggle");
        // radios.forEach((radio) => (radio.checked = false));
        // updateUI();
        // applySoilFilter();
      }
    });
  
    soilProperties.forEach((property, index) => {
      const toggleWrapper = document.createElement("div");
      toggleWrapper.className = "toggle-wrapper";
  
      const toggleContainerInner = document.createElement("div");
      toggleContainerInner.className = "toggle-inner";
  
      const toggleRadio = document.createElement("input");
      toggleRadio.type = "radio";
      toggleRadio.className = "toggle-radio";
      toggleRadio.name = "soilPropertyToggle";
      toggleRadio.id = `toggle-${index}`;
      toggleRadio.checked = false; // No default checked radio
      toggleRadio.addEventListener("change", () => {
        if (toggleRadio.checked) {
          activeProperty = property.name;
          selectedFilters[activeProperty] = property.ranges.map(range => range.value);
          const checkboxes = toggleWrapper.querySelectorAll(".checkboxButton");
          checkboxes.forEach(checkbox => (checkbox.checked = true));
        } else {
          activeProperty = null;
        }
        updateUI();
        applySoilFilter();
      });
      toggleContainerInner.appendChild(toggleRadio);
  
      const toggleLabel = document.createElement("label");
      toggleLabel.htmlFor = `toggle-${index}`;
      toggleLabel.innerText = property.name;
      toggleLabel.className = "toggle-label";
      toggleContainerInner.appendChild(toggleLabel);
  
      toggleWrapper.appendChild(toggleContainerInner);
  
      const checkboxGroup = document.createElement("div");
      checkboxGroup.className = "checkbox-group";
      checkboxGroup.style.display = "none"; // Hidden by default
  
      property.ranges.forEach((range, rangeIndex) => {
        const optionWrapper = document.createElement("div");
        optionWrapper.className = "checkbox-option";
  
        const checkbox = document.createElement("input");
        checkbox.type = "checkbox";
        checkbox.className = "checkboxButton";
        checkbox.name = `${property.name}-checkbox`;
        checkbox.value = range.value;
        checkbox.checked = false; // No default checked checkboxes
        checkbox.addEventListener("change", () => {
          if (checkbox.checked) {
            selectedFilters[property.name].push(range.value);
          } else {
            selectedFilters[property.name] = selectedFilters[property.name].filter(
              (val) => val !== range.value
            );
          }
          applySoilFilter();
        });
  
        const colorSwatch = document.createElement("div");
        colorSwatch.className = "color-swatch";
        colorSwatch.style.backgroundColor = range.color;
        colorSwatch.style.width = "15px";
        colorSwatch.style.height = "15px";
        colorSwatch.style.display = "inline-block";
        colorSwatch.style.marginRight = "5px";
        colorSwatch.style.clipPath = "none";
        colorSwatch.style.borderRadius = "50%";
        colorSwatch.style.flexShrink = "0";
       colorSwatch.style.alignSelf = "center";
  
        const label = document.createElement("label");
        label.htmlFor = `${property.name}-${rangeIndex}`;
        label.innerText = range.label;
        label.className = "checkbox-label";
  
        optionWrapper.appendChild(colorSwatch);
        optionWrapper.appendChild(label);
        optionWrapper.appendChild(checkbox);
        checkboxGroup.appendChild(optionWrapper);
      });
  
      toggleWrapper.appendChild(checkboxGroup);
      toggleContainer.appendChild(toggleWrapper);
    });
  
    const resetButton = document.createElement("button");
    resetButton.className = "resetButton";
    resetButton.innerText = "Reset";
    resetButton.addEventListener("click", () => {
      activeProperty = null;
      soilProperties.forEach((prop) => {
        selectedFilters[prop.name] = [];
        const checkboxes = document.getElementsByName(`${prop.name}-checkbox`);
        checkboxes.forEach((checkbox) => (checkbox.checked = false));
      });
      const radios = document.getElementsByName("soilPropertyToggle");
      radios.forEach((radio) => (radio.checked = false));
      updateUI();
      applySoilFilter();
    });
  
    updateUI();
    applySoilFilter(); // Initial call, but layer stays hidden
  
    if (eventItem.layer.type !== "group") {
      eventItem.panel = {
        content: [
          "legend",
          "Soil Property Filters",
          " ",
          toggleContainer,
          " ",
          resetButton,
          " ",
          // divSummaryButton,
        ],
        className: "esri-icon-sliders-horizontal",
        title: "Layer Properties",
      };
    }
  }


  if (eventItem.title === "Soil Analysis (Raster View)") {
    const soilLayers = [
      {
        title: "Soil PH",
        ranges: [
          { label: "Acidic (<5.5)", min: 0, max: 5.5, color: [255, 99, 71, 255] }, 
          { label: "Neutral (5.5-7.4)", min: 5.5, max: 7.4, color: [153, 152, 61, 255] }, 
          { label: "Alkaline (>7.4)", min: 7.4, max: 14, color: [50, 205, 50, 255] }, 
        ],
        defaultRenderer: new RasterStretchRenderer({
          type: "raster-stretch",
          stretchType: "min-max",
          colorRamp: {
            type: "algorithmic",
            fromColor: [255, 99, 71, 255], 
            toColor: [50, 205, 50, 255],   
          },
          statistics: [{ min: 0, max: 14 }],
        }),
      },
      {
        title: "Soil Carbon",
        ranges: [
          { label: "Low SOC (0-50)", min: 0, max: 50, color: [211, 211, 211, 255] }, // Gray
          { label: "High SOC (50-100)", min: 50, max: 100, color: [138, 43, 226, 255] }, // Purple
        ],
        defaultRenderer: new RasterStretchRenderer({
          type: "raster-stretch",
          stretchType: "min-max",
          colorRamp: {
            type: "algorithmic",
            fromColor: [211, 211, 211, 255], 
            toColor: [138, 43, 226, 255],   
          },
          statistics: [{ min: 0, max: 100 }],
        }),
      },
      {
        title: "Sand Texture",
        ranges: [
          { label: "Low Sand (<60%)", min: 0, max: 60, color: [220, 20, 60, 255] }, // Crimson
          { label: "Moderate Sand (60-70%)", min: 60, max: 70, color: [135, 112, 65, 255] }, // Midpoint between crimson and green
          { label: "High Sand (>70%)", min: 70, max: 100, color: [50, 205, 50, 255] }, // Green
        ],
        defaultRenderer: new RasterStretchRenderer({
          type: "raster-stretch",
          stretchType: "min-max",
          colorRamp: {
            type: "algorithmic",
            fromColor: [220, 20, 60, 255], 
            toColor: [50, 205, 50, 255],  
          },
          statistics: [{ min: 0, max: 100 }],
        }),
      },
    ];
    eventItem.visible = false;

    eventItem.layer.layers.forEach((layer, index) => {
      const soilLayerConfig = layer.title
        ? soilLayers.find((config) => config.title === layer.title)
        : soilLayers[index];

      if (soilLayerConfig) {
        const sublayerItem = eventItem.children.find((child) => child.layer === layer);
        if (sublayerItem) {
          sublayerItem.title = soilLayerConfig.title;
          sublayerItem.visible = false;

          const checkboxContainer = document.createElement("div");
          checkboxContainer.className = "checkbox-container";

          soilLayerConfig.ranges.forEach((range) => {
            const optionWrapper = document.createElement("div");
            optionWrapper.className = "checkbox-option";

            const colorSwatch = document.createElement("div");
            colorSwatch.className = "color-swatch-raster";
            colorSwatch.style.backgroundColor = `rgba(${range.color[0]}, ${range.color[1]}, ${range.color[2]}, ${range.color[3] / 255})`;
            colorSwatch.style.width = "15px";
            colorSwatch.style.height = "15px";
            colorSwatch.style.display = "inline-block";
            colorSwatch.style.marginRight = "5px";
            colorSwatch.style.borderRadius = "50%";

            const label = document.createElement("label");
            label.innerText = range.label;
            label.className = "checkbox-label";

            optionWrapper.appendChild(colorSwatch);
            optionWrapper.appendChild(label);
            checkboxContainer.appendChild(optionWrapper);
          });

          const resetButton = document.createElement("button");
          resetButton.className = "resetButton";
          resetButton.innerText = "Reset";
          resetButton.addEventListener("click", () => {
            layer.renderer = soilLayerConfig.defaultRenderer;
            layer.visible = false;
            sublayerItem.visible = false;
          });

          sublayerItem.panel = {
            content: [
              "legend",
              `${soilLayerConfig.title} Legend`,
              " ",
              checkboxContainer,
              " ",
              // resetButton,
              " ",
            ],
            className: "esri-icon-sliders-horizontal",
            title: "Layer Properties",
          };
        } else {
          console.warn(`Sublayer item not found for: ${soilLayerConfig.title}`);
        }
      } else {
        console.warn(`No config found for sublayer at index ${index}`);
      }
    });
  }


if (eventItem.title === "Maize Yield (Projected)") {
  eventItem.visible = false;

  eventItem.layer.layers.forEach((layer, index) => {
    if (index === 0) {
      const sublayerItem = eventItem.children.find((child) => child.layer === layer);
      if (sublayerItem) {
        const maizeYieldRanges = [
          { label: "Maize Yield (1-2.99 t/ha)", color: [139, 105, 20, 255] },   
          { label: "Maize Yield (3-5 t/ha)", color: [184, 134, 11, 255] },   
          { label: "Maize Yield (5-8 t/ha)", color: [218, 165, 32, 255] },     
          { label: "Maize Yield (8-12 t/ha)", color: [255, 215, 0, 255] }      
        ];

        const checkboxContainer = document.createElement("div");
        checkboxContainer.className = "checkbox-container";

        maizeYieldRanges.forEach((range) => {
          const optionWrapper = document.createElement("div");
          optionWrapper.className = "checkbox-option";

          const colorSwatch = document.createElement("div");
          colorSwatch.className = "color-swatch-raster";
          colorSwatch.style.backgroundColor = `rgba(${range.color[0]}, ${range.color[1]}, ${range.color[2]}, ${range.color[3]})`;
          colorSwatch.style.width = "15px";
          colorSwatch.style.height = "15px";
          colorSwatch.style.display = "inline-block";
          colorSwatch.style.marginRight = "5px";
          colorSwatch.style.borderRadius = "50%";

          const label = document.createElement("label");
          label.innerText = range.label;
          label.className = "checkbox-label";

          optionWrapper.appendChild(colorSwatch);
          optionWrapper.appendChild(label);
          checkboxContainer.appendChild(optionWrapper);
        });

        const resetButton = document.createElement("button");
        resetButton.className = "resetButton";
        resetButton.innerText = "Reset";
        resetButton.addEventListener("click", () => {
          layer.renderer = new RasterStretchRenderer({
            type: "raster-stretch",
            stretchType: "min-max",
            colorRamp: {
              type: "algorithmic",
              fromColor: [139, 105, 20, 255],  
              toColor: [255, 215, 0, 255]    
            },
            statistics: [{ min: 1, max: 11 }]
          });
          layer.visible = true;
        });

        sublayerItem.panel = {
          content: [
            "legend",
            "Maize Yield Legend",
            " ",
            checkboxContainer,
            " ",
            // resetButton,
            " "
          ],
          className: "esri-icon-sliders-horizontal",
          title: "Layer Properties"
        };
      }
    }
  });
}

if (eventItem.title === "Maize Suitability (Projected)") {
  eventItem.visible = false;

  eventItem.layer.layers.forEach((layer, index) => {
    if (index === 0) {
      const sublayerItem = eventItem.children.find((child) => child.layer === layer);
      if (sublayerItem) {
        const maizeSuitabilityRanges = [
          { label: "Very Low", color: [255, 0, 0, 255] },     
          { label: "Low to Moderate", color: [170, 43, 0, 255] }, 
          { label: "Moderate to High", color: [85, 85, 0, 255] },
          { label: "High", color: [0, 128, 0, 255] }       
        ];

        const checkboxContainer = document.createElement("div");
        checkboxContainer.className = "checkbox-container";

        maizeSuitabilityRanges.forEach((range) => {
          const optionWrapper = document.createElement("div");
          optionWrapper.className = "checkbox-option";

          const colorSwatch = document.createElement("div");
          colorSwatch.className = "color-swatch-raster";
          colorSwatch.style.backgroundColor = `rgba(${range.color[0]}, ${range.color[1]}, ${range.color[2]}, 1)`; // Solid colors
          colorSwatch.style.width = "15px";
          colorSwatch.style.height = "15px";
          colorSwatch.style.display = "inline-block";
          colorSwatch.style.marginRight = "5px";
          colorSwatch.style.borderRadius = "50%";

          const label = document.createElement("label");
          label.innerText = range.label;
          label.className = "checkbox-label";

          optionWrapper.appendChild(colorSwatch);
          optionWrapper.appendChild(label);
          checkboxContainer.appendChild(optionWrapper);
        });

        const resetButton = document.createElement("button");
        resetButton.className = "resetButton";
        resetButton.innerText = "Reset";
        resetButton.addEventListener("click", () => {
          layer.renderer = new RasterStretchRenderer({
            type: "raster-stretch",
            stretchType: "min-max",
            colorRamp: {
              type: "algorithmic",
              fromColor: [255, 0, 0, 255],    
              toColor: [0, 128, 0, 255]      
            },
            statistics: [{ min: 0, max: 10000 }]
          });
          layer.visible = true;
        });

        sublayerItem.panel = {
          content: [
            "legend",
            "Maize Suitability Legend",
            " ",
            checkboxContainer,
            " ",
            // resetButton,
            " "
          ],
          className: "esri-icon-sliders-horizontal",
          title: "Layer Properties"
        };
      }
    }
  });
}

if (eventItem.title === "Mazie Growing Area (Main Season)") {
  eventItem.visible = false;

        const maizeAreaRanges = [    
          { label: "Maize Areas", color: [255, 255, 0, 255] },      
        ];

        const checkboxContainer = document.createElement("div");
        checkboxContainer.className = "checkbox-container";

        maizeAreaRanges.forEach((range) => {
          const optionWrapper = document.createElement("div");
          optionWrapper.className = "checkbox-option";

          const colorSwatch = document.createElement("div");
          colorSwatch.className = "color-swatch-raster";
          colorSwatch.style.backgroundColor = `rgba(${range.color[0]}, ${range.color[1]}, ${range.color[2]}, 1)`; // Solid colors
          colorSwatch.style.width = "15px";
          colorSwatch.style.height = "15px";
          colorSwatch.style.display = "inline-block";
          colorSwatch.style.marginRight = "5px";
          colorSwatch.style.borderRadius = "50%";

          const label = document.createElement("label");
          label.innerText = range.label;
          label.className = "checkbox-label";

          optionWrapper.appendChild(colorSwatch);
          optionWrapper.appendChild(label);
          checkboxContainer.appendChild(optionWrapper);
        });

        if (eventItem.layer.type !== "group") {
          eventItem.panel = {
            content: [
              // "legend",
              "Maize Area Legend",
              " ",
              checkboxContainer,
            ],
            className: "esri-icon-sliders-horizontal",
            title: "Layer Properties",
          };
        }
}

  function sliderResetCPOtaggedMills() {
    daysSinceLastDiscoverySlider.values = [0];
    tonnesBoughtTaggedMillsSlider.values = [0];
    numberOfTxnSlider.values = [0];
  }

  //CPO mill clustered layer with slider
  if (eventItem.title === "PIND Mills") {
    aveP1PerMillSlider = showCPOMillClusters(
      eventItem,
      aveP1PerMillSlider,
      Slider,
      gtAveP1PerMill,
      ltAveP1PerMill,
      handleClusteredMillSummary
    );
  }

  return {
    weeklyP1YieldSlider,
    oilPalmClusterSlider,
    p1LGASummarySlider,
    buidingFootprintSlider,
    daysSinceLastDiscoverySlider,
    tonnesBoughtTaggedMillsSlider,
    numberOfTxnSlider,
    aveP1PerMillSlider,
    sliderTonnesAtField,
    sliderDaysSincePurchase,
    pickupVehicleCheckboxes,
  };
}
