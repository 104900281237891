import { arcgisURL } from "@/config/api-config";

const maizeYieldLayer = {
  title: "Maize Yield (Projection)",
  url: arcgisURL + "Maize_Yield_Biomass_Centroids/FeatureServer",
  opacity: 1, 
  geometryType: "point",
  visible: false,
  renderer: {
    type: "class-breaks",
    field: "Maize_Yield_t_ha", 
    classBreakInfos: [
      {
        minValue: 1,
        maxValue: 2.99, 
        symbol: {
          type: "simple-marker",
          color: [218, 165, 32, 0.2], 
          size: 8, 
          outline: { color: "white", width: 0.5 },
        },
      },
      {
        minValue: 3,
        maxValue: 5, 
        symbol: {
          type: "simple-marker",
          color: [218, 165, 32, 0.5], 
          size: 10, 
          outline: { color: "white", width: 0.5 },
        },
      },
      {
        minValue: 5,
        maxValue: 8, 
        symbol: {
          type: "simple-marker",
          color: [218, 165, 32, 0.8], // Goldenrod, 80% opacity
          size: 12,
          outline: { color: "white", width: 0.5 },
        },
      },
      {
        minValue: 8,
        maxValue: 12, 
        symbol: {
          type: "simple-marker",
          color: [255, 215, 0, 1], // Gold, fully opaque
          size: 14, 
          outline: { color: "white", width: 0.5 },
        },
      },
    ],
  },
  popupTemplate: {
    title: "Maize Yield Projection - {State}, {LGA}",
    content: [
      {
        type: "fields",
        fieldInfos: [
          {
            fieldName: "Maize_Yield_t_ha",
            label: "Maize Yield (t/ha)",
            visible: true,
            format: { digitSeparator: true, places: 2 },
          },
          {
            fieldName: "Maize_Biomass_t_ha",
            label: "Maize Biomass (t/ha)",
            visible: true,
            format: { digitSeparator: true, places: 2 },
          },
          {
            fieldName: "LGA",
            label: "LGA",
            visible: true,
          },
          {
            fieldName: "State",
            label: "State",
            visible: true,
          },
        ],
      },
    ],
  },
};

export default maizeYieldLayer;
