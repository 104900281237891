import { arcgisURL } from "@/config/api-config";

const MaizeYiedRasterLayer = {
  title: "Maize Yield Raster (Projection)",
  url: `${arcgisURL}maize_yield_raster/ImageServer`,
  opacity: 0.8,
  visible: false,
  popupTemplate: {
    title: "Maize Yield Analysis",
    content: ({ graphic }) => {
      const pixelValue = graphic.attributes["Raster.ServicePixelValue"];

      if (!pixelValue && pixelValue !== 0) {
        return "No data available at this location.";
      }
      
      const numericValue = Number(pixelValue);
      if (isNaN(numericValue)) {
        return "Invalid data at this location.";
      }
      const roundedValue = numericValue.toFixed(2);

      const formattedValue = `${Number(roundedValue).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} t/ha`;

    return `
  <div style="font-family: Arial, sans-serif; font-size: 12px;">
    <table style="width: 100%; border-collapse: collapse;">
      <tr style="background-color: #f0f0f0;">
        <td style="padding: 5px;  width: 50%; border-right: 1px solid #ddd;">Maize Yield Value</td>
        <td style="padding: 5px;">${formattedValue}</td>
      </tr>
    </table>
  </div>
      `;
    },
  },
  // legendEnabled: true,
  // listMode: "show",
};

export default MaizeYiedRasterLayer;
