import { arcgisURL } from "@/config/api-config";

const classifySuitability = (score) => {
  if (score < 2500) return "Very Low";
  if (score < 5000) return "Low to Moderate";
  if (score < 7500) return "Moderate to High";
  return "High";
};

const MaizeSuitabilityRasterLayer = {
  title: "Maize Suitability Raster (Projection)",
  url: `${arcgisURL}maize_suitability/ImageServer`,
  opacity: 0.8,
  visible: false,
  popupTemplate: {
    title: "Maize Suitability Analysis",
    content: ({ graphic }) => {
      const pixelValue = graphic.attributes["Raster.ServicePixelValue"];
      if (!pixelValue && pixelValue !== 0) {
        return "No data available at this location.";
      }
      const suitabilityClass = classifySuitability(pixelValue);

      return `
        <div style="font-family: Arial, sans-serif; font-size: 12px;">
          <table style="width: 100%; border-collapse: collapse;">
            <tr style="background-color: #f0f0f0;">
              <td style="padding: 5px; width: 50%; border-right: 1px solid #ddd;">Maize Suitability Value</td>
              <td style="padding: 5px;">${pixelValue.toLocaleString()}</td>
            </tr>
            <tr style="background-color: #fbfbfb;">
              <td style="padding: 5px; width: 50%; border-right: 1px solid #ddd;"">Suitability</td>
              <td style="padding: 5px;">${suitabilityClass}</td>
            </tr>
          </table>
        </div>
      `;
    },
  },
  // legendEnabled: true,
  // listMode: "show",
};

export default MaizeSuitabilityRasterLayer;
