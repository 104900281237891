import { arcgisURL } from "@/config/api-config";

const maizeSoilAnalysisLayer = {
  title: "Soil Analysis",
  url: arcgisURL + "Nigeria_LGA_BEI_Maize_2023_SoilGrids/FeatureServer",
  opacity: 0.8,
  geometryType: "point",
  visible: false,
  // 🔥 Renderer with Categorized Values which could be dynamic , currently sand
  renderer: {
    type: "unique-value",
    field: "SandDescription", // Default category to sand texture
    outline: {
      color: "white",
      width: 0.5,
    },
    uniqueValueInfos: [
      {
        value: "Low sand (<60%) - Biochar impact on moisture retention is lower",
        symbol: { type: "simple-marker", color: "#DC143C", size: 12, opacity: 0.8 },
        label: "Low sand (<60%) - Biochar impact on moisture retention is lower",
      },
      {
        value: "Moderate sand (60-70%) - Biochar enhances water-holding",
        symbol: { type: "simple-marker", color: "#F7DC6F", size: 12,  opacity: 0.8  },
        label: "Moderate sand (60-70%) - Biochar enhances water-holding",
      },
      {
        value: "High sand (>70%) - Biochar significantly improves moisture",
        symbol: { type: "simple-marker", color: "#32CD32", size: 12,  opacity: 0.8  },
        label: "High sand (>70%) - Biochar significantly improves moisture",
      }
    ],
  },

  // 🔥 Dynamic Popup Template
  popupTemplate: {
    title: "Biochar Effectiveness - {ADM2_NAME}, {ADM1_NAME}",
    content: [
      {
        type: "fields",
        fieldInfos: [
          {
            fieldName: "SOC",
            label: "Soil Carbon (g/kg)",
            visible: true,
            format: { digitSeparator: true },
          },
          {
            fieldName: "SoilCarbonDescription",
            label: "Soil Carbon Description",
            visible: true,
          },

          {
            fieldName: "SoilPH",
            label: "Soil pH",
            visible: true,
            format: { digitSeparator: true, places: 2 },
          },
          {
            fieldName: "SoilPHDescription",
            label: "Soil pH Description",
            visible: true,
          },

          {
            fieldName: "Sand",
            label: "Sand Texture (%)",
            visible: true,
            format: { digitSeparator: true, places: 2 },
          },
          {
            fieldName: "SandDescription",
            label: "Sand Texture Description",
            visible: true,
          },

          {
            fieldName: "BEI",
            label: "Biochar Effective Index (range 0-1)",
            visible: true,
            format: { digitSeparator: true, places: 2 },
          },
          { fieldName: "ADM2_NAME", label: "LGA", visible: true },
          { fieldName: "ADM1_NAME", label: "State", visible: true },
        ],
      },
    ],
  },
};

export default maizeSoilAnalysisLayer;
