import { arcgisURL } from "@/config/api-config";

const maizeSuitabilityLayer = {
  title: "Maize Suitability (Projection)",
  url: arcgisURL + "Maize_Suitability_Centriods/FeatureServer",
  opacity: 0.8,
  geometryType: "point",
  visible: false,
  renderer: {
    type: "unique-value",
    field: "Suitability_Class", 
    symbol: {
      type: "simple-marker",
      color: "#008000", 
      size: 12, 
      outline: {
        color: "white",
        width: 0.5,
      },
    },
    uniqueValueInfos: [
        {
            value: "Very Low",
            symbol: {
              type: "simple-marker",
              color: "red",
              size: 12,
              opacity: 0.8,
            },
            label: "Very Low",
          },
          {
            value: "Low to Moderate",
            symbol: {
              type: "simple-marker",
              color: "orange",
              size: 12,
              opacity: 0.8,
            },
            label: "Low to Moderate",
          },
          {
            value: "Moderate to High",
            symbol: {
              type: "simple-marker",
              color: "yellow",
              size: 12,
              opacity: 0.8,
            },
            label: "Moderate to High",
          },
          {
            value: "High",
            symbol: {
              type: "simple-marker",
              color: "green",
              size: 12,
              opacity: 0.8,
            },
            label: "High",
          }
      ],
  },
  popupTemplate: {
    title: "Maize Suitability @ {ADM2_NAME}, {ADM1_NAME}",
    content: [
      {
        type: "fields",
        fieldInfos: [
        //   {
        //     fieldName: "Maize_Suitability_Rainfed",
        //     label: "Maize Suitabilty Rainfed And Fertilizer",
        //     visible: true,
        //     format: { digitSeparator: true, places: 2 },
        //   },
          {
            fieldName: "Suitability_Class",
            label: "Maize Suitability",
            visible: true,
          },
          {
            fieldName: "ADM2_NAME",
            label: "LGA",
            visible: true,
          },
          {
            fieldName: "ADM1_NAME",
            label: "State",
            visible: true,
          },
        ],
      },
    ],
  },
};

export default maizeSuitabilityLayer;

