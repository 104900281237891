import { arcgisURL } from "@/config/api-config";

const commercialFarms = {
  title: "Commercial Farms",
  url: arcgisURL + "commercial_farms/FeatureServer",
  opacity: 0.8,
  renderer: {
    type: "simple",
    symbol: {
        type: "simple-marker",
        style: "square",
        size: 15,
        color: [255, 0, 0, 0.4],
        outline: {
            color: [255, 0, 0],
            width: 2,
        },
    },
},
  popupTemplate: {
    title: "Commercial Farm - {Name}",
    content: [
      {
        type: "fields",
        fieldInfos: [
          {
            fieldName: "City",
            label: "City",
          },
          {
            fieldName: "State",
            label: "State",
          },
          {
            fieldName: "Address",
            label: "Address",
          },
          {
            fieldName: "Email",
            label: "Email",
          },
          {
            fieldName: "Phone",
            label: "Phone",
          },
          {
            fieldName: "Url",
            label: "Url",
          },
          {
            fieldName: "Farm_Type",
            label: "Farm Type",
          },
          {
            fieldName: "Farm_Size_hectares_",
            label: "Farm Size (Hectares)",
          },
        ],
      },
    ],
  },
  visible: false,
//   legendEnabled: false,
};

export default commercialFarms;
