import { arcgisURL } from "@/config/api-config";

const maizeProduction2022Layer = {
    title: "Maize Production 2022",
    url: arcgisURL + "Maize_Prod_2022/FeatureServer",
    opacity: 0.8,
    renderer: {
      type: "class-breaks",
      field: "Maize_Prod", 
      classBreakInfos: [
        { minValue: 0, maxValue: 15000, symbol: { type: "simple-fill", color: "#f7fcf5", outline: { color: "white", width: 0.5 } }, label: "0-15,000 MT" },
        { minValue: 15001, maxValue: 30000, symbol: { type: "simple-fill", color: "#e2f4dd", outline: { color: "white", width: 0.5 } }, label: "15,001-30,000 MT" },
        { minValue: 30001, maxValue: 45000, symbol: { type: "simple-fill", color: "#bfe6b9", outline: { color: "white", width: 0.5 } }, label: "30,001-45,000 MT" },
        { minValue: 45001, maxValue: 60000, symbol: { type: "simple-fill", color: "#94d390", outline: { color: "white", width: 0.5 } }, label: "45,001-60,000 MT" },
        { minValue: 60001, maxValue: 75000, symbol: { type: "simple-fill", color: "#60ba6c", outline: { color: "white", width: 0.5 } }, label: "60,001-75,500 MT" },
        { minValue: 75001, maxValue: 90000, symbol: { type: "simple-fill", color: "#329b51", outline: { color: "white", width: 0.5 } }, label: "75,001-90,000 MT" },
         { minValue: 90001, maxValue: 105000, symbol: { type: "simple-fill", color: "#0d7835", outline: { color: "white", width: 0.5 } }, label: "90,001- 105,000 MT" },
        { minValue: 105001, maxValue: 120000, symbol: { type: "simple-fill", color: "#00441b", outline: { color: "white", width: 0.5 } }, label: "105,001- 120,000 MT" }
      ],
    },
    popupTemplate: {
        title: "Maize Production 2022",
        content: [
          {
            type: "fields",
            fieldInfos: [
              {
                fieldName: "Maize_Prod", 
                label: "Maize Production (MT)",
                format: { digitSeparator: true, places: 2 },
              },
            ],
          },
        ],
      },
      visible: false,
      legendEnabled: false,
  };

  const maizeProduction2023Layer = {
    title: "Maize Production 2023",
    url: arcgisURL + "maize_production_2023/FeatureServer",
    opacity: 0.8,
    renderer: maizeProduction2022Layer.renderer,
    popupTemplate: maizeProduction2022Layer.popupTemplate,
    visible: maizeProduction2022Layer.visible,
    legendEnabled: maizeProduction2022Layer.legendEnabled,
  };

export { maizeProduction2022Layer, maizeProduction2023Layer };
