<template>
  <div>
    <!-- service area toggle -->
    <div
      class="saDraw"
      style="text-align: center; justify-content: center; align-items: center"
      v-b-tooltip.hover.right="'Service Area'"
      data-v-step="2"
    >
      <img
        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRni2E8blq_JqUjzwLL5Xt6Lcxs1es-nNZ0aMXLvihdDNk9XZxW"
        width="25px"
        height="25px"
        style="
          margin-bottom: 8px;
          display: block;
          margin-left: auto;
          margin-right: auto;
        "
      />
      <label class="switch">
        <input type="checkbox" @click="toggleServiceArea" />
        <span class="slider round"></span>
      </label>
      <br />
      <button @click="clearServiceArea">X</button>
    </div>

    <!-- service area table -->
    <div
      v-b-toggle.collapse-1
      class="saTable"
      v-b-tooltip.hover.right="'Service Area Table'"
    >
      <b-collapse id="collapse-1" class="mt-2">
        <div class="saTable-b" v-show="items">
          <div v-if="isLoading" class="loader-container">
            <b-spinner
              variant="success"
              label="Loading Service Area Data"
            ></b-spinner>
          </div>
          <div v-else>
            <div v-if="itemsa.length > 0">
              <p v-if="itemsa.length > 0" class="sa">
                <b>Service Area Table</b>
              </p>
              <table v-if="itemsa.length > 0" class="service-area-table">
                <thead>
                  <tr>
                    <th>Color Code</th>
                    <!-- <th>Total Mills</th> -->
                    <!-- <th>Logistics Cost (₦)</th>
                <th>Current P1 price (₦)</th>
                <th>Estimated profit/week (₦)</th>
                <th>Total Minigrids</th>
                <th>Total Acres</th>
                <th>No of Transactions</th>
                <th>Total Tonnes Bought</th>
                <th>Weekly FFB Yield</th>
                <th>Weekly P1 Yield</th> -->
                    <th>PKS Biomass Available (T)</th>
                    <th>Biochar Output (Tonnes)</th>
                    <th>Area of Soil needed for Biochar application (HA)</th>
                    <!-- <th>Available Maize Land 2022 (HA)</th> -->
                    <th>Available Maize Land 2023 (HA)</th>
                    <th>% of Maize land used for Biochar application</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in itemsa" :key="item.id">
                    <td class="td-box" :style="{ background: item.color }"></td>
                    <!-- <td>{{ item.noOfMills }}</td> -->
                    <!-- <td>{{ item.logisticsCost }}</td>
                <td>{{ item.currentP1Price }}</td>
                <td>{{ item.profit_week }}</td>
                <td>{{ item.minigridsTotal  }}</td>
                <td>{{ item.totalAcres }}</td>
                <td>{{ item.totalNoOfTransactions }}</td>
                <td>{{ item.totalTonsBought }}</td>
                <td>{{ item.weeklyFFBYield }}</td>
                <td>{{ item.weeklyP1Yield }}</td> -->
                    <td>{{ item.pksBiomass }}</td>
                    <td>{{ item.biocharOutput }}</td>
                    <td>{{ item.landForBiochar }}</td>
                    <!-- <td>{{ item.maizeLandSize2022}}</td> -->
                    <td>{{ item.maizeLandSize2023 }}</td>
                    <td>{{ item.maizeLandAvailableForBiochar }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- No Data Message (shown if no data) -->
            <div v-else class="no-data-message">
              <p>
                No service area data available. Please draw a service area to
                load the data.
              </p>
            </div>
          </div>
        </div>
      </b-collapse>
    </div>
  </div>
</template>

<script>
export default {
  name: "ServiceAreaToggle",

  data() {
    return {
      itemsa: [],
      items: [],
      saToggle: false,
    };
  },

  props: {
    view: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    serviceAreaStore() {
      return this.$store.getters["serviceArea/getServiceAreaAttributes"];
    },
    isLoading() {
      return this.$store.getters["serviceArea/getLoadingState"];
    },
  },

  methods: {
    toggleServiceArea() {
      this.saToggle = !this.saToggle;
      this.$emit("onToggle", this.saToggle);
    },

    clearServiceArea() {
      this.view.graphics.removeAll();
      // this.itemsa = this.items; //doesnt clear the array
      this.itemsa.splice(0, this.itemsa.length); //clears the array
    },
  },

  watch: {
    serviceAreaStore(newValue) {
      if (newValue) {
        this.itemsa = newValue;
      }
    },
  },
};
</script>

<style scoped>
.saDraw {
  width: 2.1em;
  position: absolute;
  display: block;
  top: 344px;
  left: 14px;
  z-index: 9980;
  padding-top: 5px;
  background-color: #fff;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
}
.slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
}
input:checked + .slider {
  background-color: #669933;
}
input:focus + .slider {
  box-shadow: 0 0 1px #669933;
}
input:checked + .slider:before {
  transform: translateX(13px);
}
/* Rounded sliders */
.slider.round {
  border-radius: 17px;
}
.slider.round:before {
  border-radius: 50%;
}

.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.saTable {
  height: 34.5px;
  width: 34.5px;
  cursor: pointer;
  position: absolute;
  display: block;
  top: 440px;
  left: 14px;
  z-index: 9980;
  background: url("https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcTfxG9wXMTUKwPcMvqoQs89kE0cLVLJN8nHNU_UQ7fHo-Gcll9B");
  background-color: #fff;
  background-repeat: no-repeat;
  background-size: 34px 34px;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
}

.saTable-b {
  padding: 20px;
  margin-top: 10px;
  margin-left: 40px;
  z-index: 9980;
  background: #fff;
  max-width: 100vw;
  margin-right: -950px;
  overflow-x: auto;
}

.sa {
  text-align: center;
}

.service-area-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: auto;
}

th,
td {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
  font-size: 70%;
  min-width: 0;
  white-space: normal;
  word-wrap: break-word;
  max-width: 400px;
}

.td-box {
  width: auto;
  height: 20px;
  padding: 0;
  border: none;
}

.loader-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.no-data-message {
  text-align: center;
  padding: 20px;
  color: #666;
}
</style>
